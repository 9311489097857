  //Function to fix menu position on scroll
  function scrollFixer(){
    var height = $(window).scrollTop();
    if(height > 1) {
      $(".main-nav").addClass("onscroll");
      $(".header-spacer").addClass("onscroll");
    }
    if(height < 1) {
      $(".main-nav").removeClass("onscroll");
      $(".header-spacer").removeClass("onscroll");
    }
  }

//Set amount to scroll
var targetPosition = 100;

// Detect whether items are in view for targeted animations
function scrolledIntoView(elem) {
	//var docViewTop = 450;
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();
		  
	var elemTop = $(elem).offset().top;
	var elemBottom = elemTop + $(elem).height() +5;
		  
	return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
function scrolledOutofView(elem) {
	//var docViewTop = 450;
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();
		  
	var elemTop = $(elem).offset().top;
	var elemBottom = elemTop + $(elem).height() +5;
		  
	return ((elemBottom >= docViewBottom) || (elemTop <= docViewTop));
}

//Animate on scroll
function checkInview() {
	var window_height = $(window).height();
	var window_top_position = $(window).scrollTop();
	var window_bottom_position = (window_top_position + window_height);
  
	$('.anim-target').each(function() {
		var $element = $(this);
		var element_height = $element.outerHeight();
		var element_top_position = $element.offset().top;
		var element_bottom_position = (element_top_position + element_height);
  
		//check to see if this current container is within viewport
		if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
			  $element.addClass('in-view');
		} else {
		  //$element.removeClass('in-view');
		}
	});
}

jQuery(document).ready(function($){

	// Add a custom open button for the mobile menu
	$('.openmenu-button').click(function() {
		$('ul.accordion-menu').slideToggle(600);
		$('.openmenu-button').toggleClass('active');
		$('.modal-menu').toggleClass('active');
	});
	$('.accordion-menu .menu-item-has-children > a').click(function(e) {
		e.preventDefault();
		$(this).parent().find(".sub-menu").slideToggle(600);
		$(this).toggleClass('active');
	});

	//LANG PICKER
	$('button.language').on('click', function() {
		$(this).toggleClass('active');
		$(this).children('.language-selector').toggleClass('open');
	});

	// Set heights for submenu containers based on the height of the tallest element
	function blockEqualiser(){
		//Equalise heights of client boxes
        $('.submenu-wrapper').each(function() {
            $(this).find('.submenu-column').height('auto');
            // Cache the highest
            var highestBox = 0;
            $('.submenu-column', this).each(function() {
                if($(this).height() > highestBox) {
                    highestBox = $(this).height();
                }
            });  
            $('.submenu-column',this).height(highestBox);
        });	
	}
	blockEqualiser();
	
	$('.search-trigger').click(function(){
		$(this).toggleClass('active');
		$('.header-search').toggleClass('active');
	});
	
	// SLICK SLIDER
	$('.hero-slider').slick({
		dots: false,
		arrows: false,
		infinite: true,
		speed: 750,
		autoplay: true,
  		autoplaySpeed: 6000,
		fade: true,
		cssEase: 'linear',
		lazyLoad: 'ondemand',
		pauseOnHover: false
	});
	$('.cwc-vertical-text-carousel').slick({
		prevArrow:"<span class='slick-prev slick-up'></span>",
		nextArrow:"<span class='slick-next slick-down'></span>",
		autoplay: false,
		arrows: true,
		dots: false,
		slidesToShow: 7,
		slidesToScroll: 3,
		infinite: true,
		draggable: true,
		pauseOnHover: false,
		vertical: true,
		speed: 1000,
		autoplaySpeed: 2000,
		asNavFor: '.cwc-content-slider-fade',
		focusOnSelect: true,
		responsive: [
			{
			  breakpoint: 960,
			  settings: {
			    slidesToShow: 2,
			    slidesToScroll: 1,
			    centerMode: true,
			    vertical: false
			  }
			}
		]
	});
	$('.cwc-content-slider-fade').slick({
		dots: false,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		speed: 300,
		autoplay: false,
		fade: true,
		cssEase: 'linear',
		lazyLoad: 'ondemand',
		pauseOnHover: false,
		asNavFor: '.cwc-vertical-text-carousel',
	});
	$('.text-carousel-three-standard').slick({
		prevArrow:"",
		nextArrow:"<span class='slick-next slick-right'></span>",
		autoplay: false,
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		pauseOnHover: false,
		speed: 1000,
		cssEase: 'ease',
		responsive: [
			{
			  breakpoint: 1300,
			  settings: {
			    slidesToShow: 2
			  }
			},
			{
			  breakpoint: 960,
			  settings: {
			    slidesToShow: 1,
			    //vertical: true
			  }
			}
		]
	});

	$('.text-carousel-three-offset').slick({
		prevArrow:"",
		nextArrow:"<span class='slick-next slick-right'></span>",
		autoplay: false,
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		pauseOnHover: false,
		speed: 1000,
		cssEase: 'ease',
		responsive: [
			{
			  breakpoint: 1300,
			  settings: {
			    slidesToShow: 2
			  }
			},
			{
			  breakpoint: 960,
			  settings: {
			    slidesToShow: 1
			  }
			}
		]
	});

	$('.timeline-gallery').slick({
		prevArrow:"<span class='slick-prev slick-left'></span>",
		nextArrow:"<span class='slick-next slick-right'></span>",
		autoplay: false,
		arrows: true,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		pauseOnHover: false,
		speed: 1000,
		fade: true,
		cssEase: 'ease'
	});


	/* Form Label movements */

	$(document).on('focus','input',function() {
		$(this).parent().addClass('active');
	});
	$(document).on('focus','textarea',function() {
		$(this).parent().addClass('active');
	});

	$(document).on("change keyup", 'input',function(){
	    $(this).parent().addClass('active');
	});
	$(document).on("change keyup", 'textarea',function(){
	    $(this).parent().addClass('active');
	});

	$(document).on('blur','input',function() {
	    if( $(this).val().length === 0 ) {
	      	$(this).parent().removeClass('active');
	    }
	});
	$(document).on('blur','textarea',function() {
	    if( $(this).val().length === 0 ) {
	      	$(this).parent().removeClass('active');
	    }
	});

	$('input, textarea').each(function( index, element ) {
		if( $(element).val().length > 1 ) {
	      	$(this).parent().addClass('active');
	    }
    });

    $('.fixed-label select').on('change', function (e) {
		$(this).parent().addClass('fixed-label-active');
    });

	//READ MORE
	$('.read-more__trigger').on('click', function() {
		$(this).siblings().addClass('visible');
		$(this).hide();
	});
	$('.read-more__trigger--less').on('click', function() {
		$(this).parent().removeClass('visible');
		$(this).parent().siblings('.read-more__trigger').show();
	});

	//HIDE/SHOW FLOATING BUTTONS
	var isFloatingClassAdded = false;

    $(window).on('scroll', function() {
        var scrollHeight = $(window).scrollTop();
        var windowHeight = $(window).height();

        if (scrollHeight >= 200 && !isFloatingClassAdded) {
            isFloatingClassAdded = true;
            $('.floating-buttons')
                .addClass('loading')
                .delay(100)
                .queue(function(next) {
                    $(this).addClass('loaded');
                    next();
                });
        } else if (scrollHeight <= 200 && isFloatingClassAdded) {
            isFloatingClassAdded = false;
            $('.floating-buttons').removeClass('loading loaded');
        }
    });

	// REPLACE ALL SVG IMAGES WITH INLINE SVG
	$('img.svg').each(function(){
	    var $img = $(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');

	    $.get(imgURL, function(data) {
	         //Get the SVG tag, ignore the rest
	        var $svg = $(data).find('svg');
	         //Add replaced image's ID to the new SVG
	        if (typeof imgID !== 'undefined') {
	            $svg = $svg.attr('id', imgID);
	        }
	         //Add replaced image's classes to the new SVG
	        if (typeof imgClass !== 'undefined') {
	            $svg = $svg.attr('class', imgClass+' replaced-svg');
	        }
	         //Remove any invalid XML tags as per http:validator.w3.org
	        $svg = $svg.removeAttr('xmlns:a');
	        // Replace image with new SVG
	        $img.replaceWith($svg);
	    });
	});
	$(window).scroll();
});


// When the window is scrolled
jQuery(window).scroll(function() {
	scrollFixer();
	checkInview();
	$('.morse-text.in-view').each(function() {
		$(this).children().each(function(index) {
			$(this).delay(250*index).fadeIn(250);
		});
	});
	/*$('.morse-text.in-view').children('.morse-item').each(function(index){
		$(this).delay(250*index).fadeIn(250);
	});*/
});

// When window is loaded
jQuery(window).load(function (){
	blockEqualiser();
	jQuery(window).scroll();
});

// When the window is resized
jQuery(window).resize(function() {
	blockEqualiser();
});

function newPopup(url) {
	popupWindow = window.open(
url,'popUpWindow','height=450,width=600,left=100,top=100,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=yes')
}

//VERTICAL TABS
document.addEventListener('DOMContentLoaded', function () {
    const navEles = document.querySelectorAll('.nav-pills'); // This will select all nav-pills elements

    navEles.forEach(ele => {
        ele.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            e.preventDefault();
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none';

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;

            checkScrollBounds();  // Check if we're at the scroll bounds
        };

        const mouseUpHandler = function () {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        ele.addEventListener('mousedown', mouseDownHandler);

        const tabHeight = ele.querySelector('.nav-link').offsetHeight;
        const tabMargin = parseFloat(window.getComputedStyle(ele.querySelector('.nav-link')).marginTop) + parseFloat(window.getComputedStyle(ele.querySelector('.nav-link')).marginBottom);
        const scrollDistance = tabHeight + tabMargin;
        const totalTabsHeight = (tabHeight + tabMargin) * ele.children.length;

        // Get the associated scrollUp and scrollDown buttons for this nav-pills element
        const scrollUpBtn = ele.previousElementSibling;
        const scrollDownBtn = ele.nextElementSibling;

        if (totalTabsHeight <= 415) {  // If total height of tabs is less or equal to container's max height
            scrollUpBtn.style.display = 'none';
            scrollDownBtn.style.display = 'none';
        } else {
            scrollUpBtn.addEventListener('click', function() {
                ele.scrollTop -= scrollDistance;
                checkScrollBounds();
            });

            scrollDownBtn.addEventListener('click', function() {
                ele.scrollTop += scrollDistance;
                checkScrollBounds();
            });
        }

        // Check if scroll has reached the bounds and disable/enable buttons accordingly
        function checkScrollBounds() {
            if (ele.scrollTop <= 0) {
                scrollUpBtn.setAttribute('disabled', 'disabled');
            } else {
                scrollUpBtn.removeAttribute('disabled');
            }

            if (ele.scrollTop + ele.clientHeight >= ele.scrollHeight) {
                scrollDownBtn.setAttribute('disabled', 'disabled');
            } else {
                scrollDownBtn.removeAttribute('disabled');
            }
        }

        checkScrollBounds();  // Initial check
    });
});


$ = jQuery.noConflict();

var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);